import React, { useState, useContext } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PortableText from 'react-portable-text';

import {
	Grid,
	Typography,
	Button,
	makeStyles,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Collapse,
	IconButton,
	withStyles,
	useMediaQuery,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import { ExpandedSection } from './ExpandedSection';
import { MobileFeatures } from './Mobile/MobileFeatures';
import FormModalContext from '../../../context/FormModalContext';

const useStyles = makeStyles((theme) => ({
	featureCell: {
		cursor: 'pointer',
		userSelect: 'none',
	},
	featureWrapper: {
		display: 'flex',
		marginLeft: '1rem',
	},
	featureDescription: {
		margin: '.5rem 0 0 1rem',
		fontSize: '1.125rem',
		fontWeight: 400,
		color: '#5A7184',
	},
	headerCell: {
		background: theme.workwaveBlue,
		borderLeft: '2px solid #C3CAD9',
		borderTop: '2px solid #C3CAD9',
		// borderRadius: '20px 0px 0px 0px',
	},
	header: {
		fontWeight: 600,
		marginLeft: '1rem',
		color: 'white',
	},
	sectionHeader: {
		fontWeight: 700,
		color: theme.workwaveBlue,
	},
	subHeader: {
		color: theme.workwaveBlue,
	},
  disclaimer: {
    fontStyle: 'italic',
    fontSize: '1rem',
    marginTop: '-1rem',
  },
	button: {
		cursor: 'pointer',
		opacity: 1,
		textDecoration: 'none',
		transition: 'all .5s',
		'&:hover': {
			opacity: 0.8,
		},
	},
	expandText: {
		cursor: 'pointer',
		textAlign: 'center',
		paddingBottom: '4rem',
		fontWeight: 600,
		opacity: 1,
		transition: 'all .5s',
		paddingTop: '2rem',
		'&:hover': {
			opacity: 0.8,
		},
	},
}));

const StyledCell = withStyles({
	root: {
		border: '2px solid #C3CAD9',
	},
})(TableCell);

const StyledBody = withStyles({
	root: {
		borderRadius: '0px 20px 20px 20px',
	},
})(TableBody);

const Feature = ({ feature, logos }) => {
	const [open, setOpen] = useState(false);

	const classes = useStyles();

	return (
		<StyledCell
			component='th'
			scope='row'
			onClick={() => setOpen(!open)}
			className={classes.featureCell}
			style={{
				width: logos.length === 1 ? '80%' : logos.length === 3 ? '60%' : '70%',
			}}>
			<div className={classes.featureWrapper}>
				<Typography
					variant='h6'
					color='primary'
					style={{ fontSize: '1.4rem', fontWeight: 600 }}>
					{feature.featureTitle}
				</Typography>
				<IconButton
					aria-label='expand row'
					size='small'
					color='secondary'
					style={{ marginLeft: '.5rem' }}>
					{open ? <RemoveIcon /> : <AddIcon />}
				</IconButton>
			</div>
			<Collapse in={open} timeout='auto' unmountOnExit>
				<Typography variant='body1' className={classes.featureDescription}>
					{feature.featureDescription}
				</Typography>
			</Collapse>
		</StyledCell>
	);
};

export const SoftwareFeatures = ({
	softwareFeatures,
	header,
	subheader,
	modal,
	accentColor,
  pricing,
}) => {
	const classes = useStyles();
	const {
		comparisonHeader,
    alternateTextHeaders,
		logos,
		features,
		ctaLineTitle,
		ctaRows,
		expandText,
		hideText,
		expandedListHeader,
		expandedListSubheader,
		associatedProducts,
	} = softwareFeatures;

	const [expanded, setExpanded] = useState(false);
	const md = useMediaQuery('(max-width: 960px)');

	const { handleModalClick } = useContext(FormModalContext);

	return (
		<>
			{!modal && (
				<Grid
					container
					direction='column'
					justifyContent='center'
					alignContent='center'
					spacing={2}
					style={{ textAlign: 'center', paddingTop: !modal && '4rem' }}>
					<Grid item>
            {pricing ? (
              <PortableText
                content={header}
                serializers={{
                  h2: ({ children }) => (
                    <Typography variant='h2' className={classes.sectionHeader}>
                      {children}
                    </Typography>
                  ),
                  code: ({ children }) => (
                    <Typography variant='body1' className={classes.disclaimer}>
                      {children}
                    </Typography>
                  ),
                }}
              />
              ) : (
              <Typography variant='h2' className={classes.sectionHeader}>
							  {header}
						  </Typography>
              )
            }

					</Grid>

					<Grid item xs={12} md={8}>
						<Typography body='body1' className={classes.subHeader}>
							{subheader}
						</Typography>
					</Grid>
				</Grid>
			)}

			{md ? (
				<MobileFeatures
					features={features}
					comparisonHeader={comparisonHeader}
          headers={alternateTextHeaders}
					logos={logos}
					ctaLineTitle={ctaLineTitle}
					ctaRows={ctaRows}
					associatedProducts={associatedProducts}
					modal={modal}
				/>
			) : (
				<TableContainer style={{ padding: '2rem 0' }}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell align='center' className={classes.headerCell}>
									<Typography variant='h5' className={classes.header}>
										{comparisonHeader}
									</Typography>
								</TableCell>
								{logos.map((logo, index) => (
									<StyledCell align='center' key={index}>
										<GatsbyImage
											image={logo.asset?.gatsbyImageData}
											imgStyle={{ objectFit: 'contain' }}
										/>
									</StyledCell>
								))}
							</TableRow>
						</TableHead>
						{/* all features are listed similarly in sanity. we take the top 10 here and the rest go to the show all expanded component below */}
						<StyledBody>
							{features.slice(0, 10).map((feature, index) => (
								<TableRow key={index}>
									<Feature feature={feature} logos={logos} />
									<StyledCell align='center'>
										{feature.colOne && (
											<FontAwesomeIcon
												icon={['fas', 'check']}
												size='3x'
												style={{
													background: '#E5EAF4',
													borderRadius: '8px',
													padding: '6px 8px',
													color: '#2a7abc',
												}}
											/>
										)}
									</StyledCell>
									{/* uses the length of the logos array to determine if these cells will be filled. further checks the boolean attached */}
									{logos.length >= 2 && (
										<StyledCell align='center'>
											{feature.colTwo && (
												<FontAwesomeIcon
													icon={['fas', 'check']}
													size='3x'
													style={{
														background: '#E5EAF4',
														borderRadius: '8px',
														padding: '6px 8px',
														color: '#0f95a4',
													}}
												/>
											)}
										</StyledCell>
									)}

									{logos.length === 3 && (
										<StyledCell align='center'>
											{feature.colThree && (
												<FontAwesomeIcon
													icon={['fas', 'check']}
													size='3x'
													style={{
														background: '#E5EAF4',
														borderRadius: '8px',
														padding: '6px 8px',
														color: '#055291',
													}}
												/>
											)}
										</StyledCell>
									)}
								</TableRow>
							))}

							<TableRow>
								<StyledCell colSpan={1} variant='footer'>
									<Typography
										variant='h6'
										color='secondary'
										style={{
											fontWeight: 600,
											fontSize: '1.4rem',
											marginLeft: '1rem',
										}}>
										Ready to learn more?
									</Typography>
								</StyledCell>

								<StyledCell
									colSpan={3}
									align='center'
									variant='footer'
									// href={cta.ctaLink}
									onClick={handleModalClick}
									target='_blank' aria-label="Opens in a new tab"
									style={{
										background: '#055291',
									}}
									className={classes.button}>
									<Typography
										variant='body1'
										style={{
											color: 'white',
											textTransform: 'uppercase',
											fontWeight: 600,
										}}>
										CHAT WITH US TODAY
									</Typography>
								</StyledCell>
							</TableRow>
						</StyledBody>
					</Table>
				</TableContainer>
			)}
			<Collapse in={expanded}>
				<ExpandedSection
					header={expandedListHeader}
					subheader={expandedListSubheader}
					features={features.slice(10)}
					logos={logos}
				/>
			</Collapse>
			<Grid container alignItems='center' justifyContent='center'>
				<Button
					variant='contained'
					size='small'
					onClick={(e) => setExpanded(!expanded)}
					style={{
						background: accentColor ?? '#002D5C',
						color: 'white',
						margin: '2rem 0',
					}}>
					{expanded ? hideText : expandText}
					<IconButton
						aria-label='expand row'
						size='small'
						style={{ color: 'white' }}>
						{expanded ? <RemoveIcon /> : <AddIcon />}
					</IconButton>
				</Button>
			</Grid>
		</>
	);
};
