import React from 'react';

import { Grid } from '@material-ui/core';

import { Container, makeStyles } from '@material-ui/core';
import { SoftwareFeatures } from '../General/SoftwareFeatures';
import { PricingIntro } from './PricingIntro';
//import { CtaCard } from '../General/CtaCard';
import { PricingCard } from './PricingCard';
import { CtaCard } from '../General/CtaCard';

const useStyles = makeStyles((theme) => ({
	cards: {
		padding: '1rem 0 4rem 0',
		[theme.breakpoints.down('md')]: {
			padding: '2rem 0',
		},
	},
}));

export const PricingBody = ({
	softwareFeatures,
	intro,
	pricing,
	pricingCards,
}) => {
	const { _rawSoftwareFeaturesHeader, softwareFeaturesSubheader, cta } = pricing;
	const classes = useStyles();
	const {
		ctaBackground,
		_rawCtaBlockHeader,
		ctaSubheader,
		ctaText,
		ctaLink,
		internalLink,
	} = cta;
	return (
		<>
			<Container
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'column',
				}}>
				<PricingIntro intro={intro} />
				<Grid
					container
					direction='row'
					justifyContent='center'
					alignItems='center'
					className={classes.cards}
					spacing={4}>
					{pricingCards.map((card, index) => (
						<PricingCard card={card} key={index} />
					))}
				</Grid>
				{/* <CtaCard
					background={ctaBackground}
					header={ctaHeader}
					subheader={ctaSubheader}
					ctaText={ctaText}
				/> */}
			</Container>
			<Container>
				<SoftwareFeatures
					softwareFeatures={softwareFeatures}
					header={_rawSoftwareFeaturesHeader}
					subheader={softwareFeaturesSubheader}
          pricing
				/>
				<CtaCard
					background={ctaBackground}
					header={_rawCtaBlockHeader}
					subheader={ctaSubheader}
					ctaText={ctaText}
					ctaLink={ctaLink}
					internalLink={internalLink}
				/>
			</Container>
		</>
	);
};
